import './style.css';
import Google from '../../assets/p5_google.png';
import Apple from '../../assets/p5_apple.png';
import { Link } from 'react-router-dom';

const P5 = () => {
    return (
        <div id="page5">
            <div className="body">
                <p><font color='white'>건강</font>도 챙기고</p>
                <p><font color='white'>추억</font>도 쌓이고</p>
                <p><font color='white'>포인트</font>도 쌓이고</p>
                <div className="btn-wrap">
                    <a href="https://play.google.com/store/apps/details?id=app.kkokiyo.kr" target="_blank">
                        <img src={Google} alt="" />
                    </a>
                    <a href="https://apps.apple.com/kr/app/%EA%BC%AD%EC%9D%B4%EC%9A%94/id6670780329" target="_blank">
                        <img src={Apple} alt="" />
                    </a>
                </div>
            </div>
            <div className="bottom">
                <div className="info-wrap">
                    <div>
                        <p>꼭코퍼레이션</p>
                        <p>&nbsp;</p>
                        <p>사업자등록번호: 679-08-02775 | 대표자: 박성훈</p>
                        <p>서울특별시 중구 을지로 264, 스파크플러스 1212호</p>
                        <p>사업/제휴문의: <u>info@kkokiyo.app</u></p>
                        <p>서비스 관련문의: <u>info@kkokiyo.app</u></p>
                        <p>&copy; 2024 kkokcorporation</p>
                    </div>
                    <div>
                        <Link to="/service" target="_blank">이용약관</Link>
                        <Link to="/privacy" target="_blank">개인정보처리방침</Link>
                        <Link to="https://forms.gle/g5Jzyx8zdYh8yJwP7" target="_blank">탈퇴문의</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default P5;